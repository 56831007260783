import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import "../index.css";


export default function Careers() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container' style={{boxShadow:'0px 0px 10px rgba(51, 51, 51, 0.04)',marginTop:'70px'}}>
                <h2 className="col-title" style={{borderBottom: '1px solid black'}}>CAREERS</h2>
                {/* <p style={{textAlign: 'justify'}}>We use innovative application development techniques including agile to enhance the life-cycle of the entire development process.</p> */}
                <p>If you would you like to work in an exciting industry with a lot of growth potential, apply directly below. <br/><br/><h4>Job Openings</h4></p><br/>

                <div>

<MediaQuery minWidth={930}>
<table style={{width: '100%', border: '1px solid #e1e0e0', background: '#f4f6f8'}}>
<tbody>
<tr>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Job ID</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Job Title</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Location</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Posted On</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Proceed</th>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0618</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>IT DevOps & Cloud Engineer</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Rockville, MD 20855 </td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Sept 27<sup>th</sup>, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/92b9186c" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0619</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Jr. Software Developer</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Rockville, MD 20855 </td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Sept 27<sup>th</sup>, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/1a725ce8" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0620</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>IT Project Manager</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Rockville, MD 20855 </td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Sept 27<sup>th</sup>, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/fe26af47" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>
 
<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0621</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Imaging Specialist</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Rockville, MD 20855 </td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Sept 27<sup>th</sup>, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/0c859599" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

</tbody>
</table>
</MediaQuery>


<div className="row">
<MediaQuery maxWidth={929}>
  <div className="col-sm-12 col-md-12 col-lg-12" style={{overflowX:'scroll'}}>
<table className="col-sm-12 col-md-12 col-lg-12" style={{border: '1px solid #e1e0e0', background: '#f4f6f8',}}>
<tbody>
<tr>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Job ID</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Job Title</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Location</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Posted On</th>
<th style={{borderBottom: '1px solid #e1e0e0', borderRight: '1px solid #e1e0e0', padding: '8px', background: '#e9ecef'}}>Proceed</th>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0618</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>IT DevOps & Cloud Engineer</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Rockville, MD 20855 </td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Sept 27<sup>th</sup>, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/92b9186c" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0619</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Jr. Software Developer</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Rockville, MD 20855 </td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Sept 27<sup>th</sup>, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/1a725ce8" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>

<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0620</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>IT Project Manager</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Rockville, MD 20855 </td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Sept 27<sup>th</sup>, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/fe26af47" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>
 
<tr>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>0621</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Imaging Specialist</td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Rockville, MD 20855 </td>
<td style={{borderRight: '1px solid #e1e0e0', padding: '8px'}}>Sept 27<sup>th</sup>, 2024</td>
<td style={{padding: '8px'}}><a style={{padding: '5px 5px', border: '1px solid #333', backgroundColor: '#a8b8c7',color:'#1B2951'}} href="https://www.ziprecruiter.com/job/0c859599" target="_blank" rel="nofollow noreferrer">Apply</a></td>
</tr>


</tbody>
</table>
</div>
</MediaQuery>
</div>
</div>
<br/>


    </div>
  )
}
