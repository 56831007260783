import React from 'react'
import header from '../Assets/Img/header.png'

export default function Benefits() {
  return (
    <>
    <div className="container-fluid" style={{padding:'0',backgroundColor:'#007BFF'}}>
      <img src={header} alt='header' className="img-fluid"/>

    </div>

    <div className="container p-4" style={{padding:'20px 0px 10px 0px'}}>
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <h2>Benefits Plan Summary</h2>
          <h3 className="">Leave</h3>
          <ul className="list" style={{padding:'10px'}}>
            <li>10 Days Accrued Paid time off (PTO) annually to be used for Vacation and other personal matters</li>
            <li>5 accrued Sick days for medical purposes</li>
            <li>10 Paid Federal holidays including New Years Day, Martin Luther King, Memorial Day,Independence Day, Labor Day, Thanksgiving Day, Christmas day and more.</li>
          </ul>
        </div>
      </div>
    </div>

    <div className="container p-4" style={{padding:'10px 0px'}}>
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <h3>Insurance</h3>
			 <table style= {{border: '1px solid #15244f', color:'#15244f',fontSize:'18px',fontWeight:'400'}}>
				<tbody>
				<tr>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px',width: '34%',}}>Medical: CareFirst</th>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px',width: '33%',}}>Dental: Plan Includes</th>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px',width: '33%',}}>Vision: Highlights include</th>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f', paddingLeft: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li style={{paddingLeft:'9px'}}>24/7 Nurse Hotline</li>
				<li style={{paddingLeft:'9px'}}>Access to wellness Program</li>
				<li style={{paddingLeft:'9px'}}>Deductible: $3000 Individual / $6000 family.</li>
				<li style={{paddingLeft:'9px'}}>Preventive Services</li>
				<li style={{paddingLeft:'9px'}}>Drug Coverage</li>
				<li style={{paddingLeft:'9px'}}>Health Savings Account</li>
				<li style={{paddingLeft:'9px'}}>and much more</li>
                </ul>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
                    
				<li style={{paddingLeft:'9px'}}>Two Oral Exams Per Benefit period</li>
				<li style={{paddingLeft:'9px'}}>Two cleanings</li>
				<li style={{paddingLeft:'9px'}}>X-Rays</li>
				<li style={{paddingLeft:'9px'}}>Major discounts on Fillings</li>
				<li style={{paddingLeft:'9px'}}>Deductible: $25 In-Network / $75 out of Network</li>
				<li style={{paddingLeft:'9px'}}>and more</li>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
				<li style={{paddingLeft:'9px'}}>$10 Copay towards Eye exam</li>
				<li style={{paddingLeft:'9px'}}>Davis Frames included</li>
				<li style={{paddingLeft:'9px'}}>Excellent Lens coverage</li>
				<li style={{paddingLeft:'9px'}}>Low cost Contact lenses</li>
				<li style={{paddingLeft:'9px'}}>and other benefits</li>
				</td>
				</tr>
				</tbody>
			</table>
        </div>
      </div>
    </div>


    <div className="container p-4" style={{padding:'10px 0px'}}>
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <h3>Medical Plan Premium</h3>
			 <table style={{border: '1px solid #15244f', color:'#15244f',fontSize:'18px',fontWeight:'400',width:'100%'}}>
				<tbody>
				<tr>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px',fontWeight: '500',}} colspan="4">The monthly rates below are for individuals; family rates are based on the combination of the population covered.  For all employees, Eigennet will contribute 170 dollars per month towards the medical premium in total.</th>
				</tr>
				<tr>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px', width:'25%'}}>Age Band </th>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px'}}>Rate</th>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px', width:'25%'}}>Age Band </th>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px'}}>Rate</th>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>0-14</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$303.11 </p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>42</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$524.99</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>15</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$330.05</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>43</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$537.67</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>16</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$340.35</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>44</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$553.52</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>17</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$350.65</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>45</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$572.14</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>18</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$361.75</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>46</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$594.33</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>19</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$372.84</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>47</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$619.29</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>20</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$384.33</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>48</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$647.82</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>21-24</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$396.22</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>49</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$675.95</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>25</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$397.80</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>50</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$707.65</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>26</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$405.73</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>51</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$738.95</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>27</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$415.24</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>52</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$773.42</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>28</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$430.69</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>53</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$808.29</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>29</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$443.37</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>54</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$845.93</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>30</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$449.71</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>55</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$883.57</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>31</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$459.22</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>56</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$924.38</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>32</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$468.73</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>57</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$965.59</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>33</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$474.67</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>58</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$1,009.57</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>34</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$481.01</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>59</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$1,031.36</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>35</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$484.18</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>60</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$1,075.34</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>36</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$478.35</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>61</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$1,113.38</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>37</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$490.52</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>62</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$1,138.34</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>38</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$493.69</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>63</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$1,169.64</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>39</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$500.03</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>64</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$1,188.66</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>40</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$506.37</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>65+</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$1,188.66</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
					<p>41</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$515.88</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					&nbsp;
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					&nbsp;
				</td>
				</tr>
				</tbody>
			</table>
        </div>
      </div>
    </div>


    <div className="container p-4" style={{padding:'10px 0px'}}>
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <h3>Dental Plan Premium</h3>
			 <table style={{border: '1px solid #15244f', color:'#15244f',fontSize:'18px',fontWeight:'400',width:'100%'}}>
				<tbody>
				<tr>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px'}} colspan="2">Dental Plan Tier Rates Option 1</th>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top',width: '75.3%'}} >
				<p>Individual</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$30.31</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
				<p>Individual & Child(ren)</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$72.74</p>
				</td>
				
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
				<p>Individual & Adult</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$60.62</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
				<p>Family</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$118.21</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
				<p>Medicare Rates</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$30.31</p>
				</td>
				</tr>
				</tbody>
			</table>
        </div>
      </div>
    </div>


    <div className="container p-4" style={{padding:'10px 0px'}}>
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <h3>Vision Plan Premium</h3>
			 <table style={{border: '1px solid #15244f', color:'#15244f',fontSize:'18px',fontWeight:'400',width:'100%'}}>
				<tbody>
				<tr>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px'}} colspan="2">BlueVision Plus Option 4 (VPLMC004-U)</th>
				</tr>
				<tr>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px'}} colspan="2">Vision Plan Tier Rates</th>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign:'top',width: '75.3%'}} >
				<p>Individual</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$5.18</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
				<p>Individual & Child(ren)</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$10.88</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
				<p>Individual & Adult</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$10.36</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
				<p>Family</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$15.18</p>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}} >
				<p>Medicare Rates</p>
				</td>
				<td style={{borderRight: '1px solid #15244f',borderBottom: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					<p>$5.18</p>
				</td>
				</tr>
				</tbody>
			</table>
        </div>
      </div>
    </div>

    <div className="container p-4" style={{padding:'10px 0px'}}>
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <h3>Summary of Medical </h3>
			 <table style={{border: '1px solid #15244f', color:'#15244f',fontSize:'18px',fontWeight:'400'}}>
				<tbody>
				<tr>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px',width: '34%'}}>Services</th>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px', width:' 33%'}}>In-Network You Pay </th>
				<th style={{borderBottom: '1px solid #15244f', borderRight: '1px solid #15244f', padding: '8px',width: '33%'}}>Out-of-Network You Pay</th>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
				<p className="list-head">Preventive Services</p>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					&nbsp;
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					&nbsp;
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>Well-Child Care(including exams & immunizations)</li>
				<li>Adult Physical Examination (including routine after deductible GYN visit)</li>
				<li>Breast Cancer Screening.</li>
				<li>Pap Test</li>
				<li>Prostate Cancer Screening</li>
				<li>Colorectal Cancer Screening</li>
                </ul>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>No charge*</li>
				<li>No charge*</li>
				<li>No charge*</li>
				<li>No charge*</li>
				<li>No charge*</li>
				<li>No charge*</li>
                </ul>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>No charge*</li>
				<li>No charge* after deductible</li>
				<li>No charge*</li>
				<li>No charge* after deductible</li>
				<li>No charge* after deductible</li>
				<li>No charge* after deductible</li>
                </ul>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
				<p className="list-head">PCP and Specialist Services</p>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					&nbsp;
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					&nbsp;
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>FACILITY CHARGE 6—In addition to the physician copays/coinsurances listed below, if a service is rendered on a hospital campus.</li>
				<li>Office Visits for Illness—PC</li>
				<li>Office Visits for Illness—Specialist</li>
				<li>Allergy Testing</li>
				<li>Allergy Shots</li>
				<li>Physical, Speech, and Occupational Therapy 6 (limited to 30 visits/illness or injury/benefit period)</li>
				<li>Chiropractic (limited to 20 visits/benefit period)</li>
				<li>Acupuncture</li>
                </ul>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>Deductible, then $50 per visit</li>
				<li>Deductible, then $25 per visit</li>
				<li>Deductible, then $50 per visit</li>
				<li>Deductible, then $50 per visit</li>
				<li>Deductible, then $50 per visit</li>
				<li>Deductible, then $50 per visit</li>
				<li>Deductible, then $50 per visit</li>
				<li>Deductible, then $50 per visit</li>
                </ul>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>Deductible, then $150 per visit</li>
				<li>Deductible, then $70 per visit</li>
				<li>Deductible, then $70 per visit</li>
				<li>Deductible, then $70 per visit</li>
				<li>Deductible, then $70 per visit</li>
				<li>Deductible, then $70 per visit</li>
				<li>Deductible, then $70 per visit</li>
				<li>Deductible, then $70 per visit</li>
                </ul>
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
				<p className="list-head">Diagnostic Services</p>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					&nbsp;
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '10px',verticalAlign: 'top'}}>
					&nbsp;
				</td>
				</tr>
				<tr>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>Non-Hospital/Freestanding Facility (Labs)</li>
				<li>Hospital(Labs)</li>
				<li>Non-Hospital/Freestanding Facility (x-ray)</li>
				<li>Hospital(Labs)(x-ray)</li>
				<li>Non-Hospital/Freestanding Facility (Imaging)</li>
				<li>Hospital(Imaging)</li>
				<li>Chiropractic (limited to 20 visits/benefit period)</li>
				<li>Acupuncture</li>
                </ul>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>Deductible, then $25 per visit (LabCorp only)</li>
				<li>Deductible, then $50 per visit(preauthorization required)</li>
				<li>Deductible, then $50 per visit</li>
				<li>Deductible, then $100 per visit(preauthorization required)</li>
				<li>Deductible, then $250 per visit</li>
				<li>Deductible, then $500 per visit (preauthorization required)</li>
                </ul>
				</td>
				<td style={{borderRight: '1px solid #15244f', padding: '30px',verticalAlign: 'top'}}>
                    <ul>
				<li>Deductible, then $75 per visit</li>
				<li>Deductible, then $150 per visit</li>
				<li>Deductible, then $100 per visit</li>
				<li>Deductible, then $150 per visit</li>
				<li>Deductible, then $300 per visit</li>
				<li>Deductible, then $550 per visit</li>
                </ul>
				</td>
				</tr>
				</tbody>
			</table>
        </div>
      </div>
    </div>

    <div className="container p-4" style={{padding:'20px 0px'}}>
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <h3 className="">Retirement</h3>
            <li>Simple IRA plan allowing up to 13K towards retirement</li>
            <li>Matching options available</li>
        </div>
      </div>
    </div>
    <br/>
    </>
  )
}
